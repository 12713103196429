import { SET_ITERATION, SET_ITERATIONS } from "./constants";

const initialState = {
  iterations: [],
  iteration: {
    id: null,
    firstName: "",
    lastName: "",
    middleName: "",
    iin: "",
    phone: "",
  },
  formSaved: false,
  meeting: undefined,
};

export default function iterationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ITERATION:
      return {
        ...state,
        iteration: action.payload,
      };
    case SET_ITERATIONS:
      return {
        ...state,
        iterations: action.payload,
      };
    default:
      return state;
  }
}
