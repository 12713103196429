import { applyMiddleware, combineReducers, createStore } from "redux";
import iterationsReducer from "./iterations/reducer";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { alertsReducer } from "./alerts/reducer";

const rootReducer = combineReducers({
  iterations: iterationsReducer,
  alerts: alertsReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
