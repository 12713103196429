import { HIDE_ALERT, SHOW_ALERT } from "./constants";

export const alertsReducer = (state = null, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...action.payload,
      };
    case HIDE_ALERT:
      return null;
    default:
      return state;
  }
};
