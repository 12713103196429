import React, { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { createIteration } from "../store/iterations/actions";
import { useDispatch, useSelector } from "react-redux";
import MeetingBlock from "../components/MeetingBlock";

const SimpleFormPage = ({ match, location }) => {
  const phone = "7" + match.params.phone.substring(1);
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return Object.fromEntries(searchParams.entries());
  }, [location.search]);

  const [
    isCreateMeetingButtonDisabled,
    setIsCreateMeetingButtonDisabled,
  ] = useState(false);

  const iteration = useSelector((state) => state.iterations.iteration);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createIteration({ phone, ...params }));
  }, [dispatch, phone, params]);

  const [meeting, setMeeting] = useState();
  const handleCreateMeeting = async () => {
    setIsCreateMeetingButtonDisabled(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/zoom-meetings/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ iterationId: iteration.id }),
      }
    );
    if (response.ok) {
      const json = await response.json();
      setMeeting(json);
    }
  };

  return (
    <div className="main-container">
      <div className="row justify-content-center">
        <Col md={10} className="justify-content-center">
          <div className="text-center">
            <h1>{phone}</h1>
            <Button
              className="mt-3"
              onClick={handleCreateMeeting}
              disabled={!iteration.id || isCreateMeetingButtonDisabled}
            >
              Создать видеозвонок
            </Button>
          </div>
          {!!meeting && (
            <MeetingBlock
              joinUrl={meeting.joinUrl}
              startUrl={meeting.startUrl}
              phone={phone}
            />
          )}
        </Col>
      </div>
    </div>
  );
};

export default SimpleFormPage;
