import { SET_ITERATION, SET_ITERATIONS } from "./constants";

export const createIteration = (data) => async (dispatch) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/iterations/`,
    {
      method: "POST",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const data = await response.json();
    dispatch({
      type: SET_ITERATION,
      payload: data,
    });
    dispatch(loadIterations());
  }
};

export const loadIterations = () => async (dispatch, getState) => {
  const phone = getState().iterations.iteration.phone;
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/iterations/?phone=${phone}`,
    {
      method: "GET",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    dispatch({
      type: SET_ITERATIONS,
      payload: data,
    });
  }
};

export const saveIteration = (data) => async (dispatch, getState) => {
  const iteration = getState().iterations.iteration;

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/iterations/${iteration.id}/`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const data = await response.json();
    dispatch({
      type: SET_ITERATION,
      payload: data,
    });
  }
};
