import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import React, { useRef } from "react";

const MeetingBlock = ({ phone, joinUrl, startUrl }) => {
  const joinInputRef = useRef(null);

  const copyToClipboard = (e) => {
    joinInputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    <>
      <div className="row mt-3">
        <Col sm={7}>
          <InputGroup>
            <Form.Control
              defaultValue={joinUrl}
              ref={joinInputRef}
              className="border-success bg-white"
            />
            <InputGroup.Append>
              <Button variant="outline-success" onClick={copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} className="mr" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col>
          <a
            href={"https://wa.me/" + phone + "/?text=" + encodeURI(joinUrl)}
            className="btn btn-outline-info btn-block"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="mr-3" />
            Отправить в Whatsapp
          </a>
        </Col>
      </div>

      <a
        href={startUrl}
        className="btn btn-primary btn-block mt-5"
        target="_blank"
        rel="noreferrer noopener"
      >
        Запустить zoom
      </a>
    </>
  );
};

export default MeetingBlock;
