import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import SimpleFormPage from "./pages/SimpleFormPage";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/form/:phone" component={SimpleFormPage} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
